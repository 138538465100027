import logo from "../assets/logo.jpg";
import React from "react";



const home = () => {
    return (
        <>
            <div className="App">
                <div className='mainContent'>
                    <div className='aboutUsContainer'>
                        <h2>Thank you for visiting!</h2>
                
                        <p>Our website is currently under construction. Check back soon!</p>
                    </div>
                </div>
            </div>

        </>
    );
    
};

export default home;
