import logo from "../assets/logo.jpg";
import React from "react";


const News = () => {
  return (
    <>
      <div className="App">
        <div className='mainContent'>
          <div>
            <p>No new news at this time.</p>
          </div>
        </div>
      </div>

    </>
  );
};

export default News;
