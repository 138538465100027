import React from "react";


const Contact = (props) => {
    return (
        <>
            <div className="App">
                <div className='mainContent'>
                    <div>
                        <p>This page is still being imagined</p>
                    </div>
                </div>
            </div>

        </>
    );
};

export default Contact;
