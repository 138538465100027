import React from 'react';


function Footer() {
  return (
    <>
  <footer>
    <div className="footer">
      <h6>Copyright something something</h6>
    </div>
  </footer>
  </>
  )
}

export default Footer;